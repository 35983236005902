
import { defineComponent } from 'vue'
import DataService from '@/services/DataService'
import iMovies from '@/types/Movies'
import ResponseData from '@/types/ResponseData'

export default defineComponent({
  name: 'add',
  components: {},
  data() {
    return {
      title: '',
      year: '',
      director: '',
      posterUrl: '',
      submitted: false,
      errors: [],
    }
  },
  methods: {
    saveMovie() {
      let data = {
        title: this.title,
        year: this.year,
        director: this.director,
        posterUrl: this.posterUrl,
      }
      DataService.create(data)
        .then((response: ResponseData) => {
          console.log(response.data)
          this.submitted = true
        })
        .catch((e: Error) => {
          console.log(e)
        })
    },
    checkForm: function (e) {
      this.errors = []

      if (!this.title) {
        this.errors.push('Titre requis')
      }
      if (!this.year) {
        this.errors.push('Année requise')
      }
      if (!this.director) {
        this.errors.push('Réalisateur requis')
      }
      if (!this.posterUrl) {
        this.errors.push('Affiche requise')
      }

      if (!this.errors.length) {
        this.saveMovie()
      }

      e.preventDefault()
    },
    newMovie() {
      this.submitted = false
    },
  },
})
